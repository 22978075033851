<template>
  <div class="main">
    <h1 class="title is-1 has-text-centered headline">Explore Private Firms</h1>

    <div class="columns is-centered is-mobile switch-container">
      <b-switch type="is-green" v-model="showOnlyAccredited" size="is-small">Show accredited firms only</b-switch>
      <b-switch type="is-green" v-model="showOnlyUnderbar" size="is-small">Show firms accepting underbars only</b-switch>
    </div>

    <h1 class="title has-text-centered">Platinum Firms</h1>
    <div class="columns is-variable is-5 is-multiline is-centered firms">
      <div v-if="loading === true" class="column is-half">
        <Loader></Loader>
      </div>

      <div v-for="firm in firms"
           v-if="(firm.accredited === true || showOnlyAccredited === false) && (firm.underbar === true || showOnlyUnderbar === false) && firm.premium === true"
           :key="firm.id"
           class="column"
           v-bind:class="{
             'is-5-desktop is-half-tablet': firm.premium,
             'is-3-desktop is-3-tablet': !firm.premium
           }"
       >
        <FirmThumb :firm="firm"></FirmThumb>
      </div>
    </div>

    <h1 class="title has-text-centered">Diamond Firms</h1>
    <div class="columns is-variable is-5 is-multiline is-centered firms">
      <div v-if="loading === true" class="column is-half">
        <Loader></Loader>
      </div>

      <div v-for="firm in firms"
           v-if="(firm.accredited === true || showOnlyAccredited === false) && (firm.underbar === true || showOnlyUnderbar === false) && firm.premium === false"
           :key="firm.id"
           class="column"
           v-bind:class="{
             'is-5-desktop is-half-tablet': firm.premium,
             'is-3-desktop is-3-tablet': !firm.premium
           }"
      >
        <FirmThumb :firm="firm"></FirmThumb>
      </div>
    </div>
  </div>
</template>

<script>
import {supabase} from "../supabaseClient";
import Loader from "../components/Loader";
import FirmThumb from "../components/FirmThumb";

export default {
  name: "AllEmployers",
  title: "Firms | Adhika: Ateneo Law School Career Fair 2022",
  components: {FirmThumb, Loader},
  data() { return {
    select: this.$route.params.select,
    headline: null,
    firms: [],
    showOnlyAccredited: false,
    showOnlyUnderbar: false,
    loading: false
  }},
  methods: {
    async initializePage(select) {
      this.loading = true
      this.firms = []
      console.log(select)
      this.select = select
      switch (select) {
        case 'all': this.headline = "All Firms"; break;
        case 'firms': this.headline = "Explore Private Firms"; break;
        case 'ngo': this.headline = "Explore NGOs"; break;
        case 'gov': this.headline = "Explore Government Agencies"; break;
      }

      let query = supabase
          .from('employers')
          .select('id, name, accredited, logo, premium, underbar')
          .order('name', {ascending: true})
          .eq('cat','firms')

      const {data, error} = await query
      this.firms = data
      console.log(data)
      console.log(error)
      this.loading = false
    }
  },
  mounted() {
    this.initializePage(this.$route.params.select)
  },
  watch: {
    $route(to, from) {
      this.initializePage(this.$route.params.select)
    }
  }
}
</script>

<style scoped>
.main {
  padding: 6.9%;
  padding-top: 4.2%;
}

.headline, .firms {
  margin-top: 12px;
}

.switch-container {
  margin: 12px;
  margin-bottom: 24px;
}
</style>